<template>

	<div class="wrap h-100">

		<div class="student h-100">
			<header class="fixed-top">
				<div class="row pt-5 h-100 d-flex justify-content-center align-items-center">
					<div class="col-1"></div>
					<div class="col-5">
						<img class="student-logo" src="../assets/WHSAcademy.svg">
					</div>
					<div class="col-5 text-right">

					</div>
					<div class="col-1"></div>
				</div>
			</header>
			<div class="row h-100 d-flex justify-content-center align-items-center">
				<div class="col-1"></div>
				<div class="col-5">
					<h1>Welcome</h1>
					<p class="lead">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae blanditiis non ipsum nobis eum modi dolorum molestias! Doloribus quis dolorum amet architecto consequuntur molestias, fugiat, enim inventore quo totam cupiditate.</p>
				</div>
				<div class="col-6 d-flex justify-content-center align-items-center bg-green h-100">
					<img src="../assets/WHSA_hero.svg">
				</div>
			</div>
			<footer class="student-footer py-4 pr-3 fixed-bottom w-100">
				<div class="row d-flex justify-content-center align-items-center">
					<div class="col-6">
						<img src="../assets/WHSAcademy_logo_footer.svg">
					</div>
					<div class="col-6 d-flex justify-content-end">
						<a href="" class="me-5 nav-item">Privacy Policy</a> <a href="" class="nav-item">Terms of Service</a>
					</div>
				</div>
			</footer>
		</div>

	</div>

</template>

<script>
import HomepageMenu from "./components/HomepageMenu.vue";

export default {
	name: "HomeView",
	components: {
		HomepageMenu
	},
	data() {
		return {

		}
	}

};
</script>

<style lang="scss" scoped>
@import url('https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css');
.bg-green {
	background-color: #CBDF98;
}

.student-footer {
	background-color: #fff;
	border-top: 1px solid #eee;

	.nav-item {
		font-size: 14px;
		color: #000000b5;
	}
}

.student-logo {
	margin-left: -20px;
}

.btn-brand {
    border-radius: 50px;
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
    padding: 10px 30px;
}

.home__prefooter {

	h3 {
		line-height: 30px;
		font-weight: 700;
		font-size: 25px;
	}

	button {
		box-shadow: none !important;
		background-color: #fff !important;
		color: #2F91AE !important;
		text-transform: none;
		line-height: 30px;
		padding-top: 25px !important;
		padding-bottom: 25px !important;

		a {
			color: #2F91AE !important;
			font-weight: 800;
			font-size: 16px;
		}
	}
}

.rull-right-img {
	background: url('../assets/instant.jpg') no-repeat right;
    background-size: cover;
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
}

.position-relative .row {
	min-height: 650px;
}

.container-fluid.custom-fluid {
	// .col-2 {
	// 	flex: 0 0 11.9%;
    // 	max-width: 11.9%;
	// }

	// .col-4 {
	// 	flex: 0 0 38%;
    // 	max-width:38%;
	// }
}
.row {
	margin: 0;
}
.right-image {
	// background:url('http://localhost:8080/img/mid-2.f4732415.jpg') no-repeat right center / cover;
}

.home__hero--wrapper .btn {
	background-color: #2F91AE;
	color: #fff;
	border-color: #2F91AE;
	padding: 10px 50px;
}
</style>
